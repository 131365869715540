<script lang="ts">
    import { onMount } from 'svelte';
    import { decryptBase64 } from '../utility/crypt/decrypt.js';
    import { renderAvifToCanvas } from '../utility/render/avifToCanvas.js';

    export let post: {
        attachment_id: string;
        media_src?: string;
        'media-src'?: string;
        attachment_key: string;
        // Add any other relevant fields you want to display
    };
    export let onClose: () => void;

    let modalCanvas: HTMLCanvasElement;

    onMount(async () => {
        try {
            const mediaUrl = post.media_src || post['media-src'];
            if (!mediaUrl) throw new Error(`Media source URL is undefined for post ${post.attachment_id}`);

            const response = await fetch(mediaUrl);
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const encryptedData = await response.json();
            if (!encryptedData.ct || !encryptedData.iv) throw new Error('Encrypted data is missing required fields');

            const decryptedArrayBuffer = await decryptBase64(encryptedData.ct, encryptedData.iv, post.attachment_key);
            await renderAvifToCanvas(decryptedArrayBuffer, modalCanvas);
        } catch (error) {
            console.error(`Error rendering canvas for post ${post.attachment_id}:`, error);
        }
    });
</script>

<div class="fixed z-50 flex hidden items-center justify-center" on:click="{onClose}">
    <div class="relative overflow-auto rounded-lg bg-white" on:click|stopPropagation>
        <button class="absolute text-gray-600 hover:text-gray-800" on:click="{onClose}">
            <svg
                class="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
        </button>
        <div class="mt-4">
            <canvas bind:this="{modalCanvas}" class="h-auto"></canvas>
        </div>
        <!-- Add any additional post information here -->
    </div>
</div>

<div class="absolute flex h-fit w-full justify-center" on:click="{onClose}">
    <div class="fixed">
        <div class="flex w-fit items-center justify-center">
            <canvas bind:this="{modalCanvas}" class="w-3/4"></canvas>
        </div>
    </div>
</div>
